
import {
  defineComponent,
  ref,
  reactive,
  onBeforeMount,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { FileItem } from "@/API/types";
import {
  batchInvoiceFile,
  batchLicenseFile,
  batchFileSubmit,
  batchSubmitResult,
  lastTimeResults,
  continueUpload,
} from "@/API/rv/rvDealer";
import moment from "moment";
import downloadFile from "@/utils/rv/downloadFile";
import { guid } from "@/utils/index";
const columns = [
  {
    title: "车架号",
    dataIndex: "vinNo",
    key: "vinNo",
    width: 100,
  },
  {
    title: "文件名",
    dataIndex: "fileName",
    key: "fileName",
    width: 150,
  },
  {
    title: "提交结果",
    dataIndex: "rtnMessage",
    key: "rtnMessage",
    width: 150,
    slots: { customRender: "rtnMessage" },
  },
];
export default defineComponent({
  components: {},
  emits: ["close-batch-visible", "get-modal-info"],
  props: {
    isBatchVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    noLicense: {
      type: Boolean,
      required: false,
      default: false,
    },
    batchActiveKey: {
      type: String,
      required: true,
      default: "1",
    },
  },
  setup(props, context) {
    const { state } = useStore();

    const modal = (props as any).isBatchVisible;
    const bActiveKey = ref<string>("");
    bActiveKey.value = (props as any).batchActiveKey;
    // tab标签可否编辑
    const invoiceDisabled = ref<boolean>(false);
    const licenseDisabled = ref<boolean>(false);
    licenseDisabled.value = (props as any).noLicense;
    // 文件上传
    const invoiceUploadComplete = ref<boolean>(true);
    const invoiceFormData = new FormData();
    const invoiceFile = ref([]);
    const invoiceCount = ref<number>(0);
    const invoiceFileSize = ref<string>("");
    const licenseFormData = new FormData();
    const licenseFile = ref([]);
    const licenseCount = ref<number>(0);
    const licenseFileSize = ref<string>("");
    const licenseUploadComplete = ref<boolean>(true);
    // 上传进度条
    const invoicePercent = ref<number>(0);
    const licensePercent = ref<number>(0);
    const invoiceFileNum = ref<number>(0);
    const licenseFileNum = ref<number>(0);
    const invoiceContent = ref<string>("0/0");
    const invoiceProStatus = ref<string>("normal");
    const licenseContent = ref<string>("0/0");
    const licenseProStatus = ref<string>("normal");
    const isInvoiceSuccess = ref<boolean>(false);
    const isLicenseSuccess = ref<boolean>(false);
    const closeCount = ref<number>(0);
    // 智传发票进度条
    const invoiceConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          invoicePercent.value = complete;
          invoiceContent.value =
            Math.floor(
              (progressEvent.loaded / progressEvent.total) *
                invoiceFileNum.value
            ) +
            "/" +
            invoiceFileNum.value +
            "（" +
            complete +
            "%完成）";
        }
      },
    };
    // 智传行驶证进度条
    const licenseConfig = {
      onUploadProgress: (progressEvent: any) => {
        if (progressEvent.lengthComputable) {
          const complete =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          licensePercent.value = complete;
          licenseContent.value =
            Math.floor(
              (progressEvent.loaded / progressEvent.total) *
                licenseFileNum.value
            ) +
            "/" +
            licenseFileNum.value +
            "（" +
            complete +
            "%完成）";
        }
      },
    };
    const totalSubmit = ref<boolean>(true);
    const tempInvoiceData = ref([]);
    const tempLicenseData = ref([]);
    // 弹窗初始化
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: "",
      content: "",
      btnVisible: false,
    });
    const invoiceTit = ref<string>("文件上传");
    const licenseTit = ref<string>("文件上传");
    const isSubmit = ref<boolean>(true);
    const dataSource = ref({
      submitResult: {
        content: [],
      },
    });
    const isContinue = ref<boolean>(false);
    const isReset = ref<boolean>(false);
    const current = ref<number>(1);
    const size = ref<number>(20);
    const invoiceSubCount = ref<number>(0);
    const licenseSubCount = ref<number>(0);
    const invoiceClickCount = ref<number>(0);
    const licenseClickCount = ref<number>(0);
    const invoiceKey = ref<string>(guid());
    const licenseKey = ref<string>(guid());
    const uploadError = ref<boolean>(false);
    const timer = ref<number>(0);

    // 关闭弹窗
    const close = () => {
      closeCount.value = 1;
      clearInterval(timer.value);
      timer.value = 0;
      if (invoiceClickCount.value == 1 || licenseClickCount.value == 1) {
        if (invoiceClickCount.value == 1) {
          if (!isInvoiceSuccess.value && !uploadError.value) {
            modalParams.noticeType = "warning";
            modalParams.content =
              "正在批量上传，此操作会取消该批次文件的全部上传，确定要关闭窗口？";
            modalParams.btnVisible = true;
            Object.assign(modalData, modalParams);
            sessionStorage.setItem("stopInvoice", invoiceKey.value);
            context.emit("get-modal-info", {
              modalVisible: true,
              modalData: modalData,
            });
          } else {
            sessionStorage.setItem("stopInvoice", "");
            context.emit("close-batch-visible", {
              isBatchVisible: false,
            });
          }
        }
        if (licenseClickCount.value == 1) {
          if (!isLicenseSuccess.value && !uploadError.value) {
            modalParams.noticeType = "warning";
            modalParams.content =
              "正在批量上传，此操作会取消该批次文件的全部上传，确定要关闭窗口？";
            modalParams.btnVisible = true;
            Object.assign(modalData, modalParams);
            sessionStorage.setItem("stopLicense", licenseKey.value);
            context.emit("get-modal-info", {
              modalVisible: true,
              modalData: modalData,
            });
          } else {
            sessionStorage.setItem("stopLicense", "");
            context.emit("close-batch-visible", {
              isBatchVisible: false,
            });
          }
        }
      } else {
        context.emit("close-batch-visible", { isBatchVisible: false });
      }
    };

    // 获取提交结果
    const getBatchSubmitResult = async (batchNo: string) => {
      const params = {
        batchNo: batchNo,
        current: current.value,
        size: size.value,
      };
      await batchSubmitResult({ params }).then((res: any) => {
        dataSource.value = res;
      });
    };
    // 提交
    const batchSub = async (type: string, batchNo: string) => {
      const params = {
        batchNo: batchNo,
      };
      isContinue.value = false;
      dataSource.value = {
        submitResult: {
          content: [],
        },
      };
      try {
        const res = await batchFileSubmit({ params });
        if (res.code !== "0" || res.data.total === "") {
          if (type == "invoice") {
            invoiceProStatus.value = "exception";
            invoiceContent.value = "提交失败";
          } else {
            licenseProStatus.value = "exception";
            licenseContent.value = "提交失败";
          }
          clearInterval(timer.value);
          timer.value = 0;
          isReset.value = true;
        } else {
          const resCurrent = res.data.current;
          const useCurrent = isNaN(parseInt(resCurrent))
            ? 0
            : parseInt(resCurrent);
          const useTotal = res.data.total;
          const percentage = ((useCurrent / useTotal) * 100) | 0;
          if (type == "invoice") {
            invoiceProStatus.value = "normal";
            invoicePercent.value = percentage;
            invoiceContent.value = `${useCurrent}/${useTotal}（${invoicePercent.value}%完成）`;
          } else {
            licenseProStatus.value = "normal";
            licensePercent.value = percentage;
            licenseContent.value = `${useCurrent}/${useTotal}（${licensePercent.value}%完成）`;
          }
          if (useCurrent !== 0 && useCurrent == useTotal) {
            clearInterval(timer.value);
            timer.value = 0;
            const activeValue = ref("");
            if (bActiveKey.value == "1") {
              licenseDisabled.value = false;
              activeValue.value = "invoice";
            } else {
              invoiceDisabled.value = false;
              activeValue.value = "license";
            }
            if (type !== activeValue.value) {
              isContinue.value = false;
              dataSource.value = {
                submitResult: {
                  content: [],
                },
              };
            } else {
              await getBatchSubmitResult(batchNo);
              isContinue.value = true;
            }
          } else {
            if (percentage == 100) {
              if (timer.value) {
                clearTimeout(timer.value);
                timer.value = 0;
              }
              modalParams.content = "提交成功！";
            } else {
              if (timer.value) {
                clearTimeout(timer.value);
                timer.value = 0;
              }
              timer.value = setTimeout(
                batchSub.bind(undefined, type, batchNo),
                4000
              );
            }
          }
        }
      } catch (e) {
        if (type == "invoice") {
          invoiceProStatus.value = "exception";
          invoiceContent.value = "提交失败";
        } else {
          licenseProStatus.value = "exception";
          licenseContent.value = "提交失败";
        }
        if (timer.value) {
          clearTimeout(timer.value);
          timer.value = 0;
        }
        modalParams.content = "提交失败";
      }
    };

    // 相关操作刷新方法
    const initCon = async () => {
      // 初始化先清空计时器
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = 0;
      }
      isContinue.value = false;
      dataSource.value = {
        submitResult: {
          content: [],
        },
      };
      if (bActiveKey.value == "1") {
        if (invoiceKey.value) {
          invoiceUploadComplete.value = false;
          invoiceTit.value = "提交进度";
          isSubmit.value = false;
          invoiceFileSize.value = "";
          await batchSub("invoice", invoiceKey.value);
        }
      } else {
        if (licenseKey.value) {
          licenseUploadComplete.value = false;
          licenseTit.value = "提交进度";
          isSubmit.value = false;
          licenseFileSize.value = "";
          await batchSub("license", licenseKey.value);
        }
      }
    };

    Promise.all([
      lastTimeResults(state.user.organization.entityCode, "0"),
      lastTimeResults(state.user.organization.entityCode, "1"),
    ]).then((res) => {
      invoiceKey.value = res[0] as string;
      licenseKey.value = res[1] as string;
      initCon();
    });

    // tab切换
    const changeTab = (activeKey: string) => {
      // 切换先清空计时器
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = 0;
      }
      timer.value = 0;
      bActiveKey.value = activeKey;
      isContinue.value = false;
      dataSource.value = {
        submitResult: {
          content: [],
        },
      };
      initCon();
    };

    // 本地上传校验
    const beforeUpload = (file: FileItem, fileList: any) => {
      return new Promise((resolve, reject) => {
        const params = reactive({
          noticeType: "",
          content: "",
          btnVisible: false,
        });
        const isJpeg = file.name?.split(".")[1] === "jpeg" ? true : false;
        const isJpg = file.type === "image/jpeg";
        const isPdf = file.type === "application/pdf";
        const isOfd = (file as any).name?.indexOf(".ofd") > -1;
        // 判断是否是JPG PDF OFD格式
        if ((!isJpg || isJpeg) && !isPdf && !isOfd) {
          params.noticeType = "error";
          params.content = "文件格式错误，请按照JPG PDF OFD等格式进行文件上传";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          if (bActiveKey.value == "1") {
            invoiceCount.value = 1;
          } else {
            licenseCount.value = 1;
          }
          return reject(false);
        }
        // 判断图片大小
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          params.noticeType = "error";
          params.content = "文件大小必须小于5MB!";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          if (bActiveKey.value == "1") {
            invoiceCount.value = 1;
          } else {
            licenseCount.value = 1;
          }
          return reject(false);
        }
        // 判断图片数量
        const fileLength = fileList.length;
        // if (fileLength > 30) {
        //   params.noticeType = 'warning';
        //   params.content = '建议单次批量智传30张及以下文件数量为佳，是否继续上传？';
        //   params.btnVisible = true;
        //   Object.assign(modalData, params);
        //   context.emit('get-modal-info', {modalVisible: true, modalData: modalData});
        //   const continueWord = sessionStorage.getItem("modalContinue");
        //   if (continueWord == 'false') {
        //     return reject(false);
        //   }
        // }
        if (fileLength > 100) {
          params.noticeType = "error";
          params.content = "文件数量不能大于100张";
          params.btnVisible = false;
          Object.assign(modalData, params);
          context.emit("get-modal-info", {
            modalVisible: true,
            modalData: modalData,
          });
          if (bActiveKey.value == "1") {
            invoiceCount.value = 1;
          } else {
            licenseCount.value = 1;
          }
          return reject(false);
        }
        // 如果满足上述条件 则给对应的对象赋值
        if (bActiveKey.value == "1") {
          invoiceFile.value = fileList;
          tempInvoiceData.value = fileList;
          // Object.assign(tempInvoiceData, fileList);
        } else {
          licenseFile.value = fileList;
          tempLicenseData.value = fileList;
          // Object.assign(tempLicenseData, fileList);
        }
        return resolve(true);
      });
    };

    // 重写默认上传方法 获取合规的发票图片信息
    const invoiceCustomRequest = () => {
      if (invoiceCount.value !== 1) {
        let size = 0;
        size = invoiceFile.value.reduce(
          (size: number, ele: any) => size + ele.size,
          size
        );
        invoiceFileNum.value = invoiceFile.value.length;
        invoiceContent.value = "0/" + invoiceFileNum.value + "（0%完成）";
        invoiceFileSize.value = (size / 1024 / 1024).toFixed(2) + "MB";
        invoiceUploadComplete.value = false;
        totalSubmit.value = false;
      }
    };

    // 重写默认上传方法 获取合规的行驶证图片信息
    const licenseCustomRequest = () => {
      if (licenseCount.value !== 1) {
        let size = 0;
        size = licenseFile.value.reduce(
          (size: number, ele: any) => size + ele.size,
          size
        );
        licenseFileNum.value = licenseFile.value.length;
        licenseContent.value = "0/" + licenseFileNum.value + "（0%完成）";
        licenseFileSize.value = (size / 1024 / 1024).toFixed(2) + "MB";
        licenseUploadComplete.value = false;
        totalSubmit.value = false;
      }
    };
    // 上传发票
    const batchInvoice = async () => {
      try {
        const res = await batchInvoiceFile(
          invoiceConfig,
          invoiceKey.value,
          invoiceFormData
        );
        // 判断返回的code值 除0外都是异常
        if (res.code !== "0") {
          isInvoiceSuccess.value = false;
          invoiceProStatus.value = "exception";
          invoiceContent.value = "上传失败";
          uploadError.value = true;
          modalParams.content = res.message;
        } else {
          modalParams.content =
            "当前批次已上传完成将进入到系统自动批量提交阶段，关闭窗口后可在任务中心中关注后续系统提交进程及结果。";
          invoiceProStatus.value = "normal";
          isInvoiceSuccess.value = true;
        }
      } catch (e) {
        isInvoiceSuccess.value = false;
        uploadError.value = true;
        invoiceProStatus.value = "exception";
        invoiceContent.value = "上传失败";
        modalParams.content = "上传失败";
      }
    };
    // 上传行驶证
    const batchLicense = async () => {
      try {
        const res = await batchLicenseFile(
          licenseConfig,
          licenseKey.value,
          licenseFormData
        );
        // 判断返回的code值 除0外都是异常
        if (res.code !== "0") {
          isLicenseSuccess.value = false;
          licenseProStatus.value = "exception";
          licenseContent.value = "上传失败";
          uploadError.value = true;
          modalParams.content = res.message;
        } else {
          modalParams.content =
            "当前批次已上传完成将进入到系统自动批量提交阶段，关闭窗口后可在任务中心中关注后续系统提交进程及结果。";
          licenseProStatus.value = "normal";
          isLicenseSuccess.value = true;
        }
      } catch (e) {
        isLicenseSuccess.value = false;
        uploadError.value = true;
        licenseProStatus.value = "exception";
        licenseContent.value = "上传失败";
        modalParams.content = "上传失败";
      }
    };
    // 上传报错 关闭弹窗 重置相应数据
    const isResetModal = (param: { continue: boolean }) => {
      const isCloseModal = param.continue;
      if (isCloseModal) {
        if (invoiceProStatus.value == "exception") {
          invoiceUploadComplete.value = true;
          totalSubmit.value = true;
          invoiceProStatus.value = "normal";
          invoicePercent.value = 0;
          tempInvoiceData.value = [];
          invoiceFormData.delete("files");
        }
        if (licenseProStatus.value == "exception") {
          licenseUploadComplete.value = true;
          totalSubmit.value = true;
          licenseProStatus.value = "normal";
          licensePercent.value = 0;
          tempLicenseData.value = [];
          licenseFormData.delete("files");
        }
      }
    };
    // 图片格式错误 再次上传清空之前记录数
    const isResetCount = (param: { continue: boolean }) => {
      const resetCount = param.continue;
      if (resetCount) {
        if (bActiveKey.value == "1") {
          invoiceCount.value = 0;
        } else {
          licenseCount.value = 0;
        }
      }
    };
    // 提交
    const batchSubmit = async () => {
      if (bActiveKey.value == "1") {
        licenseDisabled.value = true;
        // 计时器每4秒请求一次提交进度接口 调用进度 刷新进度样式
        timer.value = setTimeout(() => {
          batchSub("invoice", invoiceKey.value);
        }, 4000);
      } else {
        invoiceDisabled.value = true;
        // 计时器每4秒请求一次提交进度接口 调用进度 刷新进度样式
        timer.value = setTimeout(() => {
          batchSub("license", licenseKey.value);
        }, 4000);
      }
    };
    // 智传
    const submitFile = async () => {
      totalSubmit.value = true;
      if (bActiveKey.value == "1") {
        invoiceKey.value = guid();
        licenseDisabled.value = true;
        invoiceClickCount.value = 1;
        // 转换上传数据格式
        invoiceFormData.delete("files");
        (tempInvoiceData as any).value.forEach((ele: any, index: number) => {
          invoiceFormData.append("files", ele);
        });
        invoiceProStatus.value = "normal";
        await batchInvoice();
        // 判断上传是否成功 进行相关处理
        if (isInvoiceSuccess.value) {
          modalParams.noticeType = "warning";
          modalParams.btnVisible = false;
          invoiceFileSize.value = "";
          // 上传成功调用提交接口
          batchSubmit();
        } else {
          modalParams.noticeType = "error";
          modalParams.btnVisible = false;
        }
        licenseDisabled.value = false;
      } else {
        licenseKey.value = guid();
        invoiceDisabled.value = true;
        licenseProStatus.value = "normal";
        // 清空之前请求存储数据
        licenseClickCount.value = 1;
        // 转换上传数据格式
        (tempLicenseData as any).value.forEach((ele: any, index: number) => {
          licenseFormData.append("files", ele);
        });
        await batchLicense();
        // 判断上传是否成功 进行相关处理
        if (isLicenseSuccess.value) {
          modalParams.noticeType = "warning";
          modalParams.btnVisible = false;
          licenseFileSize.value = "";
          totalSubmit.value = true;
          // 上传成功调用提交接口
          batchSubmit();
        } else {
          modalParams.noticeType = "error";
          modalParams.btnVisible = false;
        }
        invoiceDisabled.value = false;
      }
      // 判断是否点击了打断按钮
      const continueValue = sessionStorage.getItem("continue");
      if (closeCount.value == 0 || continueValue !== "true") {
        Object.assign(modalData, modalParams);
        context.emit(
          "get-modal-info",
          {
            modalVisible: true,
            modalData: modalData,
          },
          true
        );
      } else {
        closeCount.value = 0;
      }
      initCon();
    };
    // 分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1;
      size.value = pageSize;
      if (bActiveKey.value == "1") {
        getBatchSubmitResult(invoiceKey.value);
      } else {
        getBatchSubmitResult(licenseKey.value);
      }
    };
    const sizeChange = (page: number, pageSize: number) => {
      current.value = page > 0 ? page : 1;
      size.value = pageSize;
      if (bActiveKey.value == "1") {
        getBatchSubmitResult(invoiceKey.value);
      } else {
        getBatchSubmitResult(licenseKey.value);
      }
    };
    // 继续上传 清空相关数据
    const goOnUpload = () => {
      if (bActiveKey.value == "1") {
        invoiceUploadComplete.value = true;
        invoiceTit.value = "文件上传";
        invoicePercent.value = 0;
        invoiceKey.value = guid();
        invoiceSubCount.value = 0;
        invoiceFormData.delete("files");
      } else {
        licenseUploadComplete.value = true;
        licenseTit.value = "文件上传";
        licensePercent.value = 0;
        licenseKey.value = guid();
        licenseSubCount.value = 0;
        licenseFormData.delete("files");
      }
      isContinue.value = false;
      isReset.value = false;
      dataSource.value = {
        submitResult: {
          content: [],
        },
      };
      isSubmit.value = true;
      totalSubmit.value = true;
    };
    // 导出提交结果
    const exportSubmitEvent = () => {
      // 目前是batchNo写死的 假数据
      const batchNo = ref<string>("");
      if (bActiveKey.value == "1") {
        batchNo.value = invoiceKey.value;
      } else {
        batchNo.value = licenseKey.value;
      }
      const params = {
        url:
          `/rvapi/basic/apply/dealer/submitResultExport?batchNo=` +
          batchNo.value,
        method: "post",
        fileName: `${moment(new Date()).format("YYYYMMDDhhmmss")}.xlsx`,
      };
      downloadFile(params);
    };

    const handleCancelUpload = async () => {
      await continueUpload(
        state.user.organization.entityCode,
        bActiveKey.value == "1" ? "1" : "0"
      );
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = 0;
      }
      goOnUpload();
    };

    onBeforeMount(() => {
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = 0;
      }
    });

    onUnmounted(() => {
      if (timer.value) {
        clearTimeout(timer.value);
        timer.value = 0;
      }
    });

    return {
      bActiveKey,
      modal,
      close,
      beforeUpload,
      invoiceUploadComplete,
      licenseUploadComplete,
      submitFile,
      batchInvoice,
      batchLicense,
      invoiceCustomRequest,
      licenseCustomRequest,
      invoiceFile,
      licenseFile,
      invoicePercent,
      licensePercent,
      invoiceConfig,
      licenseConfig,
      invoiceProStatus,
      licenseProStatus,
      invoiceContent,
      licenseContent,
      totalSubmit,
      isInvoiceSuccess,
      isLicenseSuccess,
      modalData,
      changeTab,
      invoiceCount,
      licenseCount,
      invoiceFileSize,
      licenseFileSize,
      invoiceFileNum,
      licenseFileNum,
      tempInvoiceData,
      tempLicenseData,
      closeCount,
      modalParams,
      initCon,
      invoiceTit,
      licenseTit,
      batchSubmit,
      isSubmit,
      isContinue,
      isReset,
      columns,
      current,
      size,
      pageChange,
      sizeChange,
      dataSource,
      invoiceSubCount,
      licenseSubCount,
      invoiceClickCount,
      licenseClickCount,
      invoiceKey,
      licenseKey,
      exportSubmitEvent,
      goOnUpload,
      isResetModal,
      isResetCount,
      batchSub,
      timer,
      getBatchSubmitResult,
      invoiceDisabled,
      licenseDisabled,
      handleCancelUpload,
    };
  },
});
