
import { defineComponent, ref, reactive } from 'vue';
import { CloseCircleOutlined } from '@ant-design/icons-vue';
import { sendEditInfo, checkDate } from '@/API/rv/rvDealer';
import { FileItem } from '@/API/types';
import base64toBlob from '@/utils/rv/base64toBlob';
import { useStore } from 'vuex';
import ImagePreview from '@/views/RetailValidation/component/ImagePreview.vue';
const usageArray = ['最终用户用车', '经销商用车'];
export default defineComponent({
    components: {
        CloseCircleOutlined,
        ImagePreview,
    },
    emits: ['close-edit-info', 'get-modal-info'],
    props: {
        editInfoVisible: {
            type: Boolean,
            required: true,
            default: false,
        },
        editInfoData: {
            type: Object,
            required: true,
            default() {
                return {};
            },
        },
    },
    setup(props, context) {
        const dealerCode = useStore().state.user.organization.entityCode;
        const modal = (props as any).editInfoVisible;
        const paramsData = (props as any).editInfoData;
        const make = paramsData.make;
        const getInvoiceDate = paramsData.invoiceDate;
        const getLicenseDate = ref<string>('');
        getLicenseDate.value = paramsData.licenseDate;
        const notPassReason = ref<string>('');
        // 上传相关数据初始化
        const invoiceUploadComplete = ref<boolean>(true);
        const editFormData = new FormData();
        const invoiceFileName = ref<string>('');
        const invoiceFileSize = ref<string>('');
        const licenseVisible = ref<boolean>(false);
        const licenseUploadComplete = ref<boolean>(true);
        const licenseFileName = ref<string>('');
        const licenseFileSize = ref<string>('');
        const editPercent = ref<number>(0);
        const editProStatus = ref<string>('normal');
        const editContent = ref<string>('0%完成');
        const pdfShow = ref(false);
        const pdfObj = ref({
            base64: '',
            name: '',
            downloadUrl: '',
            docName: '',
        });
        const editConfig = {
            onUploadProgress: (progressEvent: any) => {
                if (progressEvent.lengthComputable) {
                    const complete =
                        ((progressEvent.loaded / progressEvent.total) * 100) |
                        0;
                    editPercent.value = complete;
                    editContent.value = complete + '%完成';
                }
            },
        };
        const isDisabledSubmit = ref<boolean>(true);
        const isEditSuccess = ref<boolean>(false);
        const invoiceListFileName = ref<string>('IN_' + paramsData.vinNo);
        const licenseListFileName = ref<string>('RE_' + paramsData.vinNo);
        // 表单验证
        const formRef = ref();
        const rules = {
            invoiceDate: [
                {
                    validator: (rule: any, value: any, cbfn: any) => {
                        if (value) {
                            const valueData = value.replace(/\//g, '-');
                            const beforeData = getInvoiceDate.replace(
                                /\//g,
                                '-'
                            );
                            const beforeTime = new Date(beforeData);
                            const curTime = new Date(valueData);
                            const curMonth = curTime.getMonth();
                            const beforeMonth = beforeTime.getMonth();
                            const curYear = curTime.getFullYear();
                            const beforeYear = beforeTime.getFullYear();
                            if (getInvoiceDate && curMonth !== beforeMonth) {
                                return Promise.reject('不支持跨月修改');
                            } else if (getInvoiceDate && curYear !== beforeYear) {
                                return Promise.reject('不支持跨年修改');
                            } else {
                                return Promise.resolve();
                            }
                        } else {
                            return Promise.reject('不可以为空');
                        }
                    },
                },
            ],
            licenseDate: [
                {
                    validator: (rule: any, value: any, cbfn: any) => {
                        if (value) {
                            if (!licenseVisible.value) {
                                if (getLicenseDate.value == '') {
                                    return Promise.resolve();
                                } else {
                                    if (make !== 'VAN') {
                                        const valueData = value.replace(
                                            /\//g,
                                            '-'
                                        );
                                        const beforeData = getLicenseDate.value.replace(
                                            /\//g,
                                            '-'
                                        );
                                        const beforeTime = new Date(beforeData);
                                        const curTime = new Date(valueData);
                                        const curMonth = curTime.getMonth();
                                        const beforeMonth = beforeTime.getMonth();
                                        const curYear = curTime.getFullYear();
                                        const beforeYear = beforeTime.getFullYear();
                                        if (curMonth !== beforeMonth) {
                                            return Promise.reject(
                                                '不支持跨月修改'
                                            );
                                        } else if (curYear !== beforeYear) {
                                            return Promise.reject(
                                                '不支持跨年修改'
                                            );
                                        } else {
                                            return Promise.resolve();
                                        }
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            } else {
                                return Promise.resolve();
                            }
                        } else {
                            if (paramsData.usage == '最终用户用车') {
                                return Promise.resolve();
                            } else {
                                return Promise.reject('不可以为空');
                            }
                        }
                    },
                },
            ],
            price: [
                {
                    validator: (rule: any, value: any, cbfn: any) => {
                        if (value) {
                            const valueData = value.replace(/,/g, '');
                            if (isNaN(valueData)) {
                                return Promise.reject('请输入正确的金额');
                            } else if (
                                valueData.indexOf('.') > -1 &&
                                valueData.toString().split('.')[1].length > 2
                            ) {
                                return Promise.reject('最多支持两位小数');
                            } else if (Number(valueData) < Number(100000)) {
                                return Promise.reject('金额不能小于10万');
                            } else {
                                return Promise.resolve();
                            }
                        } else {
                            return Promise.reject('不可以为空');
                        }
                    },
                },
            ],
        };
        // 弹窗初始化
        const modalData = reactive({});
        const modalParams = reactive({
            noticeType: '',
            content: '',
            btnVisible: false,
        });
        const usageChangeCount = ref<number>(0);

        const close = () => {
            context.emit('close-edit-info', { isVisible: false });
        };

        // 初始化样式
        const initCon = () => {
            if (paramsData.usage == '最终用户用车') {
                licenseVisible.value = true;
            } else {
                licenseVisible.value = false;
            }
        };
        initCon();

        // 重置上传数据
        const resetUpload = () => {
            invoiceUploadComplete.value = true;
            editFormData.delete('invoiceFile');
            editFormData.delete('licenseFile');
            invoiceFileName.value = '';
            invoiceFileSize.value = '';
            licenseVisible.value = false;
            licenseUploadComplete.value = true;
            licenseFileName.value = '';
            licenseFileSize.value = '';
            editPercent.value = 0;
            editProStatus.value = 'normal';
            editContent.value = '0%完成';
        };

        // 值发生改变 按钮可点击
        const paramsChange = () => {
            isDisabledSubmit.value = false;
        };
        // PDF下载
        const downloadFile = () => {
            const downloadElement = document.createElement('a');
            downloadElement.href = pdfObj.value.downloadUrl;
            downloadElement.download = pdfObj.value.name;
            downloadElement.click();
        };
        // 零售类型发生改变
        const usageChange = (value: string) => {
            usageChangeCount.value = 1;
            paramsChange();
            resetUpload();
            initCon();
            if (paramsData.usage == '最终用户用车') {
                paramsData.licenseDate = '';
            } else {
                paramsData.licenseDate = getLicenseDate.value;
            }
        };

        // 发票日期发生改变调用方法
        const invoiceDateChange = (value: string) => {
            if (make == 'VAN') {
                paramsData.retailDate = value;
            } else {
                if (paramsData.usage == '最终用户用车') {
                    paramsData.retailDate = value;
                }
            }
            paramsChange();
        };

        const handleShowPDF = (name: string) => {
            pdfObj.value.base64 = URL.createObjectURL(
                base64toBlob(pdfObj.value.base64, 'application/pdf')
            );
            pdfObj.value.name = name;
        };

        // 上牌日期发生改变调用方法
        const licenseDateChange = (value: string) => {
            if (make !== 'VAN') {
                if (paramsData.usage == '经销商用车') {
                    paramsData.retailDate = value;
                }
            }
            paramsChange();
        };

        // 价格发生变化调用方法
        const priceChange = () => {
            if (paramsData.price == '') {
                return;
            }
            if (paramsData.price.indexOf('.') > -1) {
                const arr = paramsData.price.split('.');
                arr[arr.length - 1] = arr[arr.length - 1].slice(0, 2);
                paramsData.price = arr.join('.');
            }
            paramsChange();
        };
        const priceBlur = () => {
            paramsData.price = paramsData.price.replace(/,/g, '');
            paramsData.price = parseFloat(paramsData.price).toFixed(2);
            paramsData.price = paramsData.price.replace(
                /(\d{1,3})(?=(\d{3})+(?:$|\.))/g,
                '$1,'
            );
            paramsChange();
        };
        // 图片验证
        const beforeUpload = (file: FileItem, fileList: any) => {
            return new Promise((resolve, reject) => {
                const params = reactive({
                    noticeType: '',
                    content: '',
                    btnVisible: false,
                });
                const isJpeg =
                    file.name?.split('.')[1] === 'jpeg' ? true : false;
                const isJpg = file.type === 'image/jpeg';
                const isPdf = file.type === 'application/pdf';
                const isOfd = (file as any).name?.indexOf('.ofd') > -1;
                if ((!isJpg || isJpeg) && !isPdf && !isOfd) {
                    params.noticeType = 'error';
                    params.content =
                        '文件格式错误，请按照JPG PDF OFD等格式进行文件上传';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                    context.emit('get-modal-info', {
                        modalVisible: true,
                        modalData: modalData,
                    });
                    return reject(false);
                }
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                    params.noticeType = 'error';
                    params.content = '文件大小必须小于5MB!';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                    context.emit('get-modal-info', {
                        modalVisible: true,
                        modalData: modalData,
                    });
                    return reject(false);
                }
                const fileLength = fileList.length;
                if (fileLength > 1) {
                    params.noticeType = 'error';
                    params.content = '每次只能上传一张零售文件';
                    params.btnVisible = false;
                    Object.assign(modalData, params);
                    context.emit('get-modal-info', {
                        modalVisible: true,
                        modalData: modalData,
                    });
                    return reject(false);
                }
                return resolve(true);
            });
        };

        // 图片转换base64格式
        const getBase64 = (file: File) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };
        // 发票上传
        const invoiceCustomRequest = async (source: any) => {
            editFormData.append('invoicefile', source.file);
            invoiceFileName.value = source.file.name;
            invoiceListFileName.value = source.file.name;
            paramsData.invoicePath = (await getBase64(source.file)) as string;
            invoiceFileSize.value =
                (source.file.size / 1024 / 1024).toFixed(2) + 'MB';
            invoiceUploadComplete.value = false;
            paramsChange();
        };
        // 行驶证上传
        const licenseCustomRequest = async (source: any) => {
            const params = reactive({
                noticeType: '',
                content: '',
                btnVisible: false,
            });

            editFormData.append('liencefile', source.file);
            licenseFileName.value = source.file.name;
            licenseListFileName.value = source.file.name;
            paramsData.licensePath = (await getBase64(source.file)) as string;
            licenseFileSize.value =
                (source.file.size / 1024 / 1024).toFixed(2) + 'MB';
            licenseUploadComplete.value = false;
            paramsChange();
        };

        // 调用提交修改信息接口
        const submitEditInfo = async (params: any) => {
            await sendEditInfo(
                editConfig,
                params.id,
                params.usage,
                params.customerName,
                params.vinNo,
                params.dealerCode,
                params.invoiceDate,
                params.licenseDate,
                params.retailDate,
                params.price,
                editFormData
            ).then((res: any) => {
                if (res.code !== '0') {
                    isEditSuccess.value = false;
                    editProStatus.value = 'exception';
                    editContent.value = '上传失败';
                    modalParams.content = res.message;
                } else {
                    editProStatus.value = 'normal';
                    modalParams.content = '编辑成功！';
                    isEditSuccess.value = true;
                }
            });
        };
        const checkInvoiceDateOk = async (params: any) => {
            isDisabledSubmit.value = true;
            editProStatus.value = 'normal';
            await submitEditInfo(params);
            if (isEditSuccess.value) {
                modalParams.noticeType = 'success';
                modalParams.btnVisible = false;
                close();
            } else {
                modalParams.noticeType = 'error';
                modalParams.btnVisible = false;
            }
            Object.assign(modalData, modalParams);
            context.emit('get-modal-info', {
                modalVisible: true,
                modalData: modalData,
            });
        };
        // 发票日期校验
        const checkInvoiceDate = async (params: any) => {
            await checkDate({ params }).then((res: any) => {
                if (res.code == '0') {
                    const params = {
                        id: paramsData.id,
                        usage: paramsData.usage,
                        customerName: paramsData.customerName,
                        vinNo: paramsData.vinNo,
                        invoiceDate: paramsData.invoiceDate.replace(
                            /\//g,
                            '%2F'
                        ),
                        licenseDate:
                            paramsData.licenseDate &&
                            paramsData.licenseDate.replace(/\//g, '%2F'),
                        retailDate: paramsData.retailDate.replace(/\//g, '%2F'),
                        price: paramsData.price.replace(/,/g, ''),
                    };
                    checkInvoiceDateOk(params);
                } else {
                    modalParams.noticeType = 'error';
                    modalParams.content = res.message;
                    modalParams.btnVisible = false;
                    Object.assign(modalData, modalParams);
                    context.emit('get-modal-info', {
                        modalVisible: true,
                        modalData: modalData,
                    });
                }
            });
        };
        // 提交
        const submitFile = () => {
            if (usageChangeCount.value == 1) {
                if (paramsData.usage == '最终用户用车') {
                    getLicenseDate.value = '';
                }
                usageChangeCount.value = 0;
            }
            formRef.value
                .validate()
                .then(async () => {
                    const params = {
                        vinNo: paramsData.vinNo,
                        type: '',
                        date: paramsData.invoiceDate,
                        dealerCode,
                    };
                    await checkInvoiceDate(params);
                })
                .catch((error: Event) => {
                    console.log('error', error);
                });
        };

        // 下载文件
        const downloadImg = (picName: string, resource: string) => {
            const a = document.createElement('a');
            a.href = resource;
            a.setAttribute('download', `${picName}.jpg`);
            a.click();
        };
        return {
            priceBlur,
            usageArray,
            modal,
            paramsData,
            close,
            getInvoiceDate,
            getLicenseDate,
            notPassReason,
            paramsChange,
            invoiceUploadComplete,
            editFormData,
            invoiceFileName,
            invoiceFileSize,
            licenseVisible,
            licenseUploadComplete,
            licenseFileName,
            editPercent,
            editProStatus,
            editContent,
            editConfig,
            isDisabledSubmit,
            licenseFileSize,
            beforeUpload,
            invoiceCustomRequest,
            licenseCustomRequest,
            submitFile,
            checkInvoiceDateOk,
            checkInvoiceDate,
            submitEditInfo,
            isEditSuccess,
            modalData,
            modalParams,
            invoiceListFileName,
            licenseListFileName,
            downloadImg,
            initCon,
            usageChange,
            resetUpload,
            invoiceDateChange,
            licenseDateChange,
            priceChange,
            getBase64,
            formRef,
            rules,
            make,
            usageChangeCount,
            pdfShow,
            pdfObj,
            handleShowPDF,
            downloadFile,
        };
    },
});
