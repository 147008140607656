export interface DealerOverviewData {
  currRetailMonth: string;
  currRetailSubmitDate: string;
  currRetailTotal: string;
  currUnSubmitTotal: string;
  currUsabilityRebateUnPassTotal: string;
  lastRetailMonth: string;
  lastRetailSubmitDate: string;
  lastRetailTotal: string;
  lastUnSubmitTotal: string;
  lastUsabilityRebateUnPassTotal: string;
}

export interface DealerTableData {
  approlStatus: string;
  customerName: string;
  id: number;
  model: string;
  retailDate: string;
  specialUsage: string;
  usabilityRebateFlag: string;
  usage: string;
  vinNo: string;
  invoicePath: string;
  licensePath: string;
  entity: string;
  failFlag: string;
  modelYear: string;
  brand: string;
}

export interface DealerFailReasonData {
  id?: number;
  comments: string;
  replyAt: string;
}

export interface DealerRetailHistoryData {
  id: number;
  invoiceBillingDate: string;
  invoiceDate: string;
  licenseBillingDate: string;
  licenseDate: string;
  saleFlag: string;
  specialUsage: string;
  usage: string;
  vinNo: string;
}

export interface DealerStatusData {
  msg: string;
  code: string;
}

export const EnumDealerApprovalStatus = {
  '0000': '待提交',
  '0001': '已过期',
  '1111': '已过期',
  '2222': '已提交',
  '3333': '已过期'
}

export const EnumDealerCheckStatus = {
  '0000': '无',
  '1111': '检查中',
  '1112': '检查中',
  '1113': '检查中',
  '2222': '有效',
  '3333': '无效'
}
